import { type TextImageOverlayBlock as TextImageOverlayBlockType } from '../../../types/graphql.page.ts'
import ArrowLink from '../arrowLink.tsx'
import Image from '../image.tsx'
import WaveTexture from '../waveTexture.tsx'
import WaveTextureLong from '../waveTextureLong.tsx'

export default function TextImageOverlayBlock({
	className,
	block,
	isPortfolio,
}: {
	isPortfolio?: boolean
	className?: string
	block: TextImageOverlayBlockType
}) {
	const textSide = block.format[0]?.imagePosition === 'left' ? 'right' : 'left'
	const waveTextureSide = textSide === 'left' && !isPortfolio ? 'right' : 'left'
	const hasLink = Boolean(block?.link?.url)
	const subHeader = block.subheader
	const hasWaveTexture =
		Array.isArray(block.format) &&
		block.format[0]?.styles &&
		block?.format[0]?.styles.includes('wave-texture')
	const hasLongWaveTexture =
		Array.isArray(block.format) &&
		block.format[0]?.styles &&
		block?.format[0]?.styles.includes('wave-texture-long')
	const accentBlock = block?.format?.find((f) =>
		f.styles?.includes('accent-box'),
	)
		? textSide === 'left'
			? 'gray-box-tl-to-br'
			: 'gray-box-tr-to-bl'
		: ''

	return (
		<article
			data-block="imageTextOverlay"
			className={`${className} relative flex h-full w-screen place-items-center md:col-span-12`}
		>
			{hasLongWaveTexture && (
				<WaveTextureLong
					className="z-20"
					side={waveTextureSide}
					position="bottom"
				/>
			)}
			{hasWaveTexture && (
				<WaveTexture
					className="z-20"
					side={waveTextureSide}
					position="bottom"
				/>
			)}
			<div
				className={`relative flex flex-1 flex-col ${
					textSide === 'left' ? 'lg:flex-row' : 'lg:flex-row-reverse'
				} md:pace-items-center`}
			>
				<div className="container z-30 flex flex-col lg:h-[728px] lg:w-full lg:justify-center">
					<div
						className={`${accentBlock ? `${accentBlock} lg:p-12` : 'lg:p-12'} ${
							textSide === 'right'
								? 'lg:right-0 lg:-mr-20'
								: 'lg:left-0 lg:-ml-20'
						} relative left-auto top-0.5 ml-0 flex w-full max-w-full flex-col space-y-4 bg-white py-8 lg:container sm:mx-auto md:w-full lg:top-0 lg:min-w-[600px] lg:max-w-[650px] lg:p-20 xl:max-w-[740px]`}
					>
						{subHeader ? <h2 className="header">{subHeader}</h2> : null}
						<div
							className="relative flex flex-col space-y-5"
							dangerouslySetInnerHTML={{
								__html: block.text,
							}}
						></div>
						{hasLink && (
							<ArrowLink
								className="pt-[15px]"
								url={block.link.url}
								type={block.link.type}
								text={block.link.text}
							/>
						)}
					</div>
				</div>
				<div
					className={`main-image ${
						textSide === 'right' ? 'left-0 right-auto' : 'left-auto right-0'
					} relative top-0.5 z-0 h-[728px] w-full overflow-x-hidden bg-green-100/5 bg-cover bg-center bg-no-repeat object-cover px-0 lg:absolute lg:top-[-1px] lg:w-[60vw]`}
				>
					$
					{block.image[0] && (
						<Image
							img={block.image[0]}
							className={`absolute left-0 top-0 z-0 h-full w-full object-cover`}
						/>
					)}
				</div>
			</div>
		</article>
	)
}
