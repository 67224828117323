export default function WaveTextureLong({
	side = 'left',
	className = '',
	position = 'top',
}: {
	position?: 'top' | 'bottom'
	side?: 'left' | 'right'
	className?: string
}) {
	return (
		<div
			className={`${className} wave-texture-side absolute -top-6 ${
				side === 'left' ? '-left-24 lg:left-0' : '-right-24 lg:right-0'
			}  ${
				position === 'top' ? '-top-6 ' : '-bottom-6'
			} z-20 h-[calc(100%)] w-[175px] bg-repeat`}></div>
	)
}
