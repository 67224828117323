export default function WaveTexture({
	side = 'left',
	className = '',
	position = 'top',
}: {
	position?: 'top' | 'bottom'
	side?: 'left' | 'right'
	className?: string
}) {
	return (
		<div
			className={`${className} wave-texture-side absolute ${
				side === 'left' ? '-left-24 lg:left-0' : '-right-24 lg:right-0'
			} ${
				position === 'top' ? '-top-72' : '-bottom-72'
			} z-20 h-[526.7px] w-[175px] bg-repeat`}></div>
	)
}
